import { useAppStore } from "../../../../hooks/useAppStore";
import "./style.scss";

const MobileNavBurger = () => {
  const isOpen = useAppStore((s) => s.mobileNavOpen);
  const setAppState = useAppStore((s) => s.setState);

  return (
    <button
      id="mobile-nav-burger"
      onClick={() => setAppState({ mobileNavOpen: !isOpen })}
      aria-label="Navigation mobile menu toggle button"
    >
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="32"
          height="3"
          transform="matrix(-1 0 0 1 37 8)"
          fill="#B9DCD2"
        ></rect>
        <rect
          width="32"
          height="3"
          transform="matrix(-1 0 0 1 37 19)"
          fill="#B9DCD2"
        ></rect>
        <rect
          width="32"
          height="3"
          transform="matrix(-1 0 0 1 37 30)"
          fill="#B9DCD2"
        ></rect>
      </svg>
    </button>
  );
};

export default MobileNavBurger;
